.header-username{
    margin: 0px 10px;
}

.chevron::before {
    border-color: #000000;
	border-style: solid;
	border-width: 1px 1px 0 0;
	content: '';
	display: inline-block;
    height: 0.45em;
	width: 0.45em;
	position: relative;
    transform: rotate(-45deg);
    cursor: pointer;
}

.chevron.bottom:before {
	top: -3px;
	transform: rotate(135deg)
}

.logo_image {
	margin-right: 30px;
}

#index .header {
	background-color: #082644;
	height: 66px;
}
